/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~bootswatch/dist/lux/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
.ng-valid[required],
.ng-valid.required {
    border-left: 5px solid green;
    /* green */
}

.ng-invalid:not(form) {
    border-left: 5px solid red;
    /* red */
}

.controlFrom {
    padding: 5px 50px;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}